<template>
  <v-app>
    <Main />
  </v-app>
</template>

<script>
import Main from "./views/Main.vue"
export default {
  name: 'App',

  data() {
    return {

    }
  },
  components: {
    Main
  }
}
</script>
<style>
body {
  font-family: 'Helvetica', sans-serif;
  overflow-x: hidden;
}
</style>
