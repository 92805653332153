<template>
    <div ref="animationContainer" class="lottie-container"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
    name: "LottieAnimation",
    props: {
        animationPath: {
            type: Object,
            required: true,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        // Load the Lottie animation when the component is mounted
        lottie.loadAnimation({
            container: this.$refs.animationContainer,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: this.animationPath, // Path to your animation file
        });
    },
};
</script>

<style scoped>
.lottie-container {
    width: 100%;
    height: 100%;
}
</style>