<template>
    <div>
        <NavMobComp />
    </div>
</template>
<script>
import NavMobComp from './NavMobComp.vue';
export default {
    data() {
        return {

        }
    },
    components: {
        NavMobComp
    }
}
</script>