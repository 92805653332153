import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'landing',
    component: () => import('../views/Portfolio/Landing/landing.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/Portfolio/Services/ServicesMain/services.vue')
  },
  {
    path: '/services/cyber-security',
    name: 'cyberSecurity',
    component: () => import('../views/Portfolio/Services/CyberSecurity/cyberSecurity.vue')
  },
  {
    path: '/services/cyber-security/endpoint-security',
    name: 'endpointSecurity',
    component: () => import('../views/Portfolio/Services/EndpointSecurity/endpointSecurity.vue'),
  },
  {
    path: '/services/cyber-security/threat-intelligence',
    name: 'threatIntelligence',
    component: () => import('../views/Portfolio/Services/ThreatIntelligence/threatIntelligence.vue'),
  },
  {
    path: '/services/ai-honeypot',
    name: 'aiHoneyPot',
    component: () => import('../views/Portfolio/Services/AiHoneyPot/aiHoneyPot.vue'),
  },
  {
    path: '/services/patch-management',
    name: 'patchManagement',
    component: () => import('../views/Portfolio/Services/PatchManagement/patchmanagement.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Portfolio/Services/Contact/contact.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/Portfolio/Services/About/about.vue'),
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('../views/Portfolio/Services/Careers/careers.vue'),
  },
  {
    path: '/academy',
    name: 'academy',
    component: () => import('../views/Portfolio/Services/Academy/academy.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
