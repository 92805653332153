<template>
    <div>
        <NavDesk v-if="!isMobile" />
        <NavMob v-if="isMobile" />
    </div>
</template>
<script>
import NavDesk from "../../components/NavBar/NavDesk.vue"
import NavMob from "../../components/NavBar/NavMob.vue";
export default {
    data() {
        return {
            isMobile: false
        }
    },
    components: {
        NavDesk,
        NavMob
    },
    mounted() {
        // Set initial screen size
        this.checkIfMobile()

        // Add event listener for resizing the window
        window.addEventListener('resize', this.checkIfMobile)
    },
    beforeDestroy() {
        // Remove event listener when component is destroyed
        window.removeEventListener('resize', this.checkIfMobile)
    },
    methods: {
        checkIfMobile() {
            this.isMobile = window.innerWidth < 960 // Adjust this breakpoint as necessary
        }
    },
    components: {
        NavDesk,
        NavMob
    }
}
</script>