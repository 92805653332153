<template>
    <v-container
        :class="ChangeNavColor === true || $route.path.length > 1 ? 'custom-container-opacity text-center pb-4' : 'custom-container text-center pb-4'"
        fluid>
        <v-col></v-col>
        <v-row justify="center" align="center">
            <!-- Logo Placement -->
            <v-col cols="auto" class="d-flex align-center">
                <v-img @click="navigateTo('/')"
                    :src="ChangeNavColor === true || $route.path.length > 1 ? require('../../assets/website_white.png') : require('../../assets/website_black.png')"
                    alt="Logo" width="200" class="mr-4"></v-img>
            </v-col>

            <!-- Menu Items -->
            <v-col v-for="(parent, index) in menuItems" :key="index" cols="auto" class="pa-0 d-flex align-center">
                <v-btn v-if="ChangeNavColor || $route.path.length > 1"
                    :append-icon="parent.children?.length > 0 ? 'mdi-chevron-down' : ''" class="text-none"
                    variant="text" :color="hoveredIndex === index ? 'purple-darken-4' : 'white'"
                    @mouseenter="hoveredIndex = index" @mouseleave="hoveredIndex = null"
                    @click="navigateTo(parent.route)">
                    <b>{{ parent.title }}</b>
                    <!-- Check if the parent item has children (for submenu) -->
                    <v-menu v-if="parent.children" open-on-hover activator="parent" location="bottom center" offset="8"
                        max-width="200" rounded="lg">
                        <v-card elevation="4" rounded="lg">
                            <v-card-text class="pa-2">
                                <v-list-item v-for="(child, childIndex) in parent.children" :key="childIndex" link
                                    rounded="lg" class="d-flex align-center">
                                    <template #title>
                                        <div class="text-body-2" @click="navigateTo(child.route)">{{ child.title }}
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-btn>



                <v-btn v-else :append-icon="parent.children?.length > 0 ? 'mdi-chevron-down' : ''" class="text-none"
                    variant="text" :color="hoveredIndex === index ? 'purple-darken-4' : 'black'"
                    @mouseenter="hoveredIndex = index" @mouseleave="hoveredIndex = null"
                    @click="navigateTo(parent.route)">
                    <b>{{ parent.title }}</b>
                    <!-- Check if the parent item has children (for submenu) -->
                    <v-menu v-if="parent.children" open-on-hover activator="parent" location="bottom center" offset="8"
                        max-width="200" rounded="lg">
                        <v-card elevation="4" rounded="lg">
                            <v-card-text class="pa-2">
                                <v-list-item v-for="(child, childIndex) in parent.children" :key="childIndex" link
                                    rounded="lg" class="d-flex align-center">
                                    <template #title>
                                        <div @click="navigateTo(child.route)" class="text-body-2">{{ child.title }}
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-btn>
            </v-col>



        </v-row>
        <v-col></v-col>
    </v-container>
</template>

<script>
export default {
    props: {
        hideSystemBar: Boolean
    },
    data() {
        return {
            hoveredIndex: null,
            ChangeNavColor: false,
            menuItems: [
                { title: 'Home', route: '/' },
                { title: 'About Us', route: '/about' },
                {
                    title: 'Cybersecurity',
                    children: [
                        { title: 'Endpoint security', route: '/services/cyber-security/endpoint-security' },
                        { title: 'Threat Intelligence', route: '/services/cyber-security/threat-intelligence' },
                    ],
                    route: '/services/cyber-security'
                },
                { title: 'AI Honeypot', route: '/services/ai-honeypot' },
                { title: 'Patch Management', route: '/services/patch-management' },
                { title: 'Contact Us', route: '/contact' },
                {
                    title: 'Careers',
                    children: [
                        { title: 'Academy', route: '/academy' }
                    ],
                    route: '/careers'
                },
                // { title: 'Academy',route: '/academy' },
            ]
        }
    },
    methods: {
        navigateTo(route) {
            if (route) {
                this.$router.push(route);
            }
        }
    },
    watch: {
        hideSystemBar: {
            handler(newVal) {
                this.ChangeNavColor = newVal
            }
        }
    },

}

</script>
<style scoped>
.custom-container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1000;
}

.custom-container-opacity {
    position: fixed;
    background-color: black;
    z-index: 1000;
}
</style>