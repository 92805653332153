<template>
    <div>
        <v-app-bar :elevation="2">
            <template v-slot:append>
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </template>
            <template v-slot:prepend>
                <v-img @click="navigateTo('/')" src="../../assets/website_black.png" alt="Logo" width="100"></v-img>
            </template>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" :location="$vuetify.display.mobile ? 'bottom' : undefined" temporary>
            <!-- Main List -->
            <v-list>
                <v-list-item v-for="(item, index) in menuItems" :key="index">

                    <!-- Loop through the children -->
                    <v-list-group v-if="!(item.children.length === 0)" v-model="open" :value="item.title" :key="index">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" :prepend-icon="item.icon" :title="item.title"></v-list-item>
                        </template>
                        <!-- Loop through the child items -->
                        <v-list-item @click="navigateTo(child.route)" v-for="(child, childIndex) in item.children"
                            :key="childIndex" :prepend-icon="child.icon" :title="child.title"></v-list-item>
                    </v-list-group>

                    <v-list-item v-else :prepend-icon="item.icon" :title="item.title"
                        @click="navigateTo(item.route)"></v-list-item>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            open: [], // This will hold the opened groups
            // Menu items with nested children
            menuItems: [
                {
                    title: 'Home',
                    icon: 'mdi-home',
                    route: '/',
                    children: []
                },
                {
                    title: 'Cybersecurity',
                    icon: 'mdi-shield-key',
                    children: [
                        { title: 'Endpoint security', icon: 'mdi-security-network', route: '/services/cyber-security/endpoint-security' },
                        { title: 'Threat Intelligence', icon: 'mdi-chip', route: '/services/cyber-security/threat-intelligence' },
                    ],
                    route: '/services/cyber-security'
                },
                {
                    title: 'AI Honeypot',
                    icon: 'mdi-beehive-outline',
                    children: [],
                    route: '/services/ai-honeypot'
                },
                {
                    title: 'Patch Management',
                    icon: 'mdi-server',
                    children: [],
                    route: '/services/patch-management'
                },
                {
                    title: 'Contact Us',
                    icon: 'mdi-phone',
                    children: [],
                    route: '/contact'
                },
                {
                    title: 'About Us',
                    icon: 'mdi-information',
                    children: [],
                    route: '/about'
                },
                {
                    title: 'Careers',
                    icon: 'mdi-account-multiple',
                    children: [
                        { title: 'Academy', icon: 'mdi-school', route: '/academy' }
                    ],
                    route: '/careers'
                },
                // {
                //     title: 'Academy',
                //     icon: 'mdi-school',
                //     children: [],
                //     route: '/academy' 
                // },
            ]
        }
    },
    methods: {
        navigateTo(route) {
            if (route) {
                this.$router.push(route);
            }
        }
    },
}
</script>