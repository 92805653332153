<template>
    <div>
        <NavBar/>
        <v-main>
            <router-view />
        </v-main>
    </div>
</template>
<script>
import NavBar from './NavBar/NavBar.vue'
export default {
    data() {
        return {
            
        }
    },
    components:{
        NavBar
    }
}
</script>
<style>
.v-main {
    --v-layout-top: 0px !important;
}
</style>