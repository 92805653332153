<template>
    <div>
        <!-- <SystemBar v-if="!hideSystemBar" /> -->
        <NavDeskComp :hideSystemBar="hideSystemBar" />
    </div>
</template>

<script>
// import SystemBar from './SystemBar.vue';
import NavDeskComp from './NavDeskComp.vue';

export default {
    data() {
        return {
            hideSystemBar: false, // controls visibility of the SystemBar
        };
    },
    components: {
        // SystemBar,
        NavDeskComp,
    },
    mounted() {
        // Add scroll event listener with debounce
        this.handleScroll = this.debounce(this.handleScroll, 0);
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollPosition = window.scrollY;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercentage = (scrollPosition / windowHeight) * 100;

            // Hide when scroll percentage reaches 30% and show again below 20%
            if (scrollPercentage >= 20 && !this.hideSystemBar) {
                this.hideSystemBar = true;
            } else if (scrollPercentage < 10 && this.hideSystemBar) {
                this.hideSystemBar = false;
            }
        },
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const later = () => {
                    timeout = null;
                    func.apply(this, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        },
    },
};
</script>